import React from 'react'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import PropTypes, { oneOfType } from 'prop-types'
import { colors, timings } from '../../style/vars'
import { FormattedMessage } from 'react-intl'

const style = {
    overlay: css`
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        width: 100%;
        height: 100%;
        width: 100lvw;
        height: 100lvh;
        background-color: ${rgba(colors.black, 0.45)};
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        height: -webkit-fill-available;
        display: flex;
        overflow-y: scroll;
        flex-direction: column;
        align-items: center;
        padding: 2em;
        &::-webkit-scrollbar {
            display: none
        };
        -ms-overflow-style: none;
        scrollbar-width: none;
    `,
    modal: css`
        margin: auto;
        position: relative;
        overflow-y: auto;
        border-radius: 0.25em;
        background-color: #232628;
        color: ${colors.white};
        box-shadow: 0 0.5em 5em 0.5em black;
        width: 32em;
        max-width: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        @media(max-width: 640px) {
            padding: 1em;
            padding-top: 2.5em;
        }

        button.btn {
            border: 2px solid #97F6CB;
            border-radius: .5em;
            background: transparent;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            padding: .5em 1.5em;
            float: right;
            margin: 1.5em 0em;
            cursor: pointer;
            transition: background ${timings.smooth};

            &:hover {
                background: ${colors.eventDark};
            }
        }

    `,
    closeBtn: css`
        position: absolute;
        top: 1em;
        right: 0.7em;
        border-radius: 0.5em;
        text-transform: uppercase;
        background-color: ${colors.black};
        color: ${colors.white};
        border: 2px solid #3A4147;
        height: 2em;
        cursor: pointer;
        outline: none;
        opacity: 0.5;
        transition: all 200ms ease;
        &:hover {
            opacity: 1;
        }
    `,
    header: css`
        background-color: #1C1F21;
        color: #97F6CB;
        width: 100%;
        padding:  1em 2.5em;
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
    `,
    content: css`
        padding: 3em;
`,
}

function Modal({
    onClickClose,
    children,
    header = null,
    ...props
}) {
    return (
        // TO DO: use dialog element
        <div
            className='closingOverlay'
            css={style.overlay}
            onClick={(e) => { ([...e.target.classList].includes('closingOverlay') && onClickClose()) }}
        >
            <div
                css={style.modal}
                {...props}
            >
                {header &&
                    <div css={style.header}>
                        {header}
                    </div>
                }
                <div css={style.content}>
                    {children}
                </div>
                <button css={style.closeBtn} type='button' onClick={onClickClose}>
                    <FormattedMessage id='closeWindow' />
                </button>
            </div>
        </div>
    )
}

Modal.propTypes = {
    onClickClose: PropTypes.func.isRequired,
    children: PropTypes.node,
    header: oneOfType([PropTypes.string, PropTypes.node]),
}

export default Modal
