
import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react'
import { getUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import Selector from '../../../components/_control/OptionsSelector'
import NumberScroller from '../../../components/_control/NumberScroller'
import useLdjsonApi from '../../../hooks/useLdjsonApi'
import FormLoader from './FormLoader'
import useBridge from '../../../hooks/useBridge'
import EventDateTimePicker from '../../../components/_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import PitchContext from '../../../context/Pitch'

export default function SeedingForm({
    eventType,
    event,
    onPostRequest
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [seedingType, setSeedingtype] = useState()
    const [seedingSpecies, setSeedingSpecies] = useState()
    const [seedingAmount, setSeedingAmount] = useState(0)
    //other
    const { data: seedingSpeciesOptions, loading: waitingForSpecies } = useBridge(`/api/current/frontend-bridge/seeding-species`)
    const { data: seedingTypeOptions, loading: waitingForTypes } = useBridge(`/api/current/frontend-bridge/seeding-types`)
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/seeding-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setSeedingtype(data.seedingType)
                setSeedingSpecies(data.species)
                setSeedingAmount(data.amount)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        //ensure required fields
        if (!seedingType) return setFormError(formatMessage({ id: 'pleaseSelectSeedingType' }))
        if (!seedingSpecies) return setFormError(formatMessage({ id: 'pleaseSelectSeedSpecies' }))
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)

        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            seedingType,
            species: seedingSpecies,
            amount: seedingAmount ? parseInt(seedingAmount, 10) : 0,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Seeding event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }
            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })
            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [seedingType, seedingSpecies, seedingAmount, pitch, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => {
        return waitingForSpecies || waitingForTypes || isLoading
    }, [waitingForSpecies, waitingForTypes, isLoading])

    return (
        <>
            {isBusy ? <FormLoader /> :
                <div css={css`display: flex; flex-direction: column; align-items: flex-start;`}>
                    <EventDateTimePicker
                        unixDate={selectedDateTime}
                        setUnixDate={setSelectedDateTime}
                    />
                    <div css={css`
                        display: flex; 
                        gap: 0 3em;
                        flex-wrap: wrap;
                    `}>
                        <NumberScroller
                            label={formatMessage({ id: 'timeTaken' })}
                            minVal={0}
                            maxVal={24}
                            state={timeTaken}
                            unit={formatMessage({ id: 'hours' })}
                            step={0.5}
                            setState={setTimeTaken}
                        />
                        <NumberScroller
                            label={formatMessage({ id: 'amount' })}
                            minVal="0"
                            maxVal="1000"
                            unit="kg"
                            setState={setSeedingAmount}
                            state={seedingAmount}
                        />
                    </div>
                    <Selector
                        label={formatMessage({ id: 'type' })}
                        optionsObject={seedingTypeOptions}
                        setState={setSeedingtype}
                        state={seedingType}
                    />

                    <Selector
                        label={formatMessage({ id: 'species' })}
                        optionsObject={seedingSpeciesOptions}
                        setState={setSeedingSpecies}
                        state={seedingSpecies}
                        btnHeight="49px"
                    />
                </div>
            }
            {formError && <div>{formError}</div>}
            <button
                className="btn"
                type="button"
                onClick={(e) => validateAndSubmit(e)}
                disabled={isBusy}
            >
                <FormattedMessage id='save' />
            </button>
        </>
    )
}