import { css } from '@emotion/react'
import React, { useContext, useEffect } from 'react'
import { Outlet } from 'react-router'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import GenericErrorBoundary from '../components/_general/GenericErrorBoundary'
import { RouteBasedAnalysisProvider } from '../context/Analysis'
import Auth from '../context/Auth'
import { RouteBasedClubProvider } from '../context/Club'
import { EventFilterProvider } from '../context/EventFilter'
import { QueryBasedPitchProvider, RouteBasedPitchProvider } from '../context/Pitch'
import { PitchListProvider } from '../context/PitchList'
import { ProfileProvider } from '../context/Profile'
import { RouteBasedTestProvider } from '../context/Test'
import { TestCategoriesProvider } from '../context/TestCategories'
import { TestCategoryGroupsProvider } from '../context/TestCategoryGroup'
import { colors } from '../style/vars'
import AccountSettings from './main/AccountSettings'
import AnalysisCreate from './main/AnalysisCreate'
import AnalysisDetails from './main/AnalysisDetails'
import AnalysisOverview from './main/AnalysisOverview'
import AnalysisTestEntry from './main/AnalysisTestEntry'
import Analytics from './main/Analytics'
import ClubList from "./main/ClubList"
import ClubPitchList from './main/ClubPitchList'
import PitchAnalyses from './main/PitchAnalyses'
import PitchEventCalendar from './main/PitchEventCalendar'
import { CalendarProvider } from '../context/Calendar'
import PitchReport from './main/PitchReport'
import TestDetails from './main/TestDetails'
import Weather from './main/Weather'
import Menu from './Menu'
import Login from './pre-auth/Login'
import ResetPasswordRequest from './pre-auth/ResetPasswordRequest'
import TopBar from "./TopBar"
import { ReporterProvider } from '../context/Reporter'
import { QueryBasedVenueProvider } from '../context/Venue'
import { GridModeProvider } from '../context/GridMode'
import { IntlProvider } from 'react-intl'
import LocaleContext from '../context/Locale'
import { TimezoneProvider } from '../context/Timezone'
import { PreferredViewContextProvider } from '../context/PreferredView'
//dnd
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import isTouchDevice from '../utils/isTouchDevice'

const dndBackend = isTouchDevice() ? TouchBackend : HTML5Backend

const style = {
    cont: css`
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        background-color: ${colors.dark};
        @media(max-width: 1280px) {
            font-size: ${12 / 16}em;
        }
        @media(max-width: 1024px) {
            font-size: ${10 / 16}em;
        }
    `,
    main: css`
        display: flex;
        flex-basis: 0;
        flex-grow: 1;
        direction: column;
        align-items: stretch;
        background-color: ${colors.dark};
        height: 100%;
        position: relative;
        -webkit-overflow-scrolling: touch;
        color: ${colors.white};
        overflow-y: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    `,
    mainContent: css`
        display: grid;
        width: 100%;
        > * {
            flex-grow: 1;
            padding: 1rem;
            padding-left: 2rem;

            @media screen and (max-width: 600px) {
                padding: 0.5em;
            }
        }
    `,
}

export default function App() {
    const { hasAuth } = useContext(Auth)
    const { locale, localeMessages } = useContext(LocaleContext)

    useEffect(() => {
        if (!window.$sleek) {
            window.$sleek = [];
            window.SLEEK_PRODUCT_ID = 778426423;
            const s = document.createElement("script")
            s.src = "https://client.sleekplan.com/sdk/e.js"
            s.async = 1
            document.getElementsByTagName("head")[0].appendChild(s)
        }
    }, [])

    return (
        <GenericErrorBoundary>
            <DndProvider backend={dndBackend}>
                <IntlProvider messages={localeMessages} locale={locale} defaultLocale={locale} key={locale}>
                    <TimezoneProvider>
                        <Router>
                            {!hasAuth
                                ?
                                <Routes>
                                    <Route path='' element={<Outlet />}>
                                        <Route exact index element={<Navigate to='/login' />} />
                                        <Route exact path='/login' element={
                                            <Login />
                                        } />
                                        <Route exact path='/request-recover' element={<ResetPasswordRequest />} />
                                        <Route path={'*'} element={<Navigate to='/login' />} />
                                    </Route>
                                </Routes>
                                :
                                <Routes>
                                    <Route path='' element={
                                        <ProfileProvider>
                                            <PreferredViewContextProvider>
                                                <div css={style.cont} >
                                                    <Menu css={css`z-index: 1000;`} />
                                                    <div css={style.main} id='pageScroller' >
                                                        <TestCategoryGroupsProvider>
                                                            <div css={style.mainContent}>
                                                                <PitchListProvider>
                                                                    <TestCategoriesProvider>
                                                                        <GenericErrorBoundary>
                                                                            <GridModeProvider>
                                                                                <Outlet />
                                                                            </GridModeProvider>
                                                                        </GenericErrorBoundary>
                                                                    </TestCategoriesProvider>
                                                                </PitchListProvider >
                                                            </div>
                                                        </TestCategoryGroupsProvider>
                                                    </div>
                                                </div>
                                            </PreferredViewContextProvider>
                                        </ProfileProvider>}
                                    >
                                        <>
                                            <Route exact path='/' element={
                                                <>
                                                    <TopBar />
                                                    <ClubList
                                                        css={css`padding: 0; padding-left: 0; .ios & { padding-bottom: 5em; }`}
                                                    />
                                                </>}
                                            />

                                            <Route
                                                exact
                                                path='/club/:clubId'
                                                element={<>
                                                    <TopBar />
                                                    <RouteBasedClubProvider>
                                                        <ClubPitchList />
                                                    </RouteBasedClubProvider>
                                                </>}
                                            />

                                            {/* PITCHES */}
                                            <Route
                                                path='/pitch/:pitchId'
                                                element={
                                                    <RouteBasedPitchProvider>
                                                        <Outlet />
                                                    </RouteBasedPitchProvider>
                                                }
                                            >
                                                <Route
                                                    exact
                                                    path=''
                                                    element={<PitchAnalyses />}
                                                />
                                                <Route
                                                    path={`analysis/:analysisId`}
                                                    element={
                                                        <RouteBasedAnalysisProvider>
                                                            <Outlet />
                                                        </RouteBasedAnalysisProvider>}
                                                >
                                                    <Route
                                                        exact
                                                        path=''
                                                        element={<AnalysisDetails />}
                                                    />
                                                    <Route
                                                        exact
                                                        path={`test-entry`}
                                                        element={<AnalysisTestEntry />}
                                                    />
                                                    <Route
                                                        path={`test/:testId`}
                                                        element={
                                                            <RouteBasedTestProvider>
                                                                <Outlet />
                                                            </RouteBasedTestProvider>
                                                        }
                                                    >
                                                        <Route
                                                            exact
                                                            path=''
                                                            element={<TestDetails
                                                                css={css`padding: 0; padding-left: 0; .ios & { padding-bottom: 5em; }`}
                                                            />}
                                                        />
                                                        <Route path={'*'} element={<Navigate to='' />} />
                                                    </Route>
                                                    <Route path={'*'} element={<Navigate to='' />} />
                                                </Route>
                                                <Route path={'*'} element={<Navigate to='' />} />
                                            </Route>

                                            {/* ACCOUNT */}
                                            <Route exact path='/account' element={<AccountSettings />} />

                                            <Route
                                                exact
                                                path='/reporting'
                                                element={
                                                    <TestCategoriesProvider>
                                                        <ReporterProvider>
                                                            <PitchReport />
                                                        </ReporterProvider>
                                                    </TestCategoriesProvider>
                                                }
                                            />

                                            {/* ANALYTICS */}

                                            <Route
                                                exact
                                                path='/analytics'
                                                element={<Analytics />}
                                            />

                                            {/* EVENT CALENDAR */}
                                            <Route
                                                exact
                                                path='/events'
                                                element={
                                                    <QueryBasedPitchProvider>
                                                        <EventFilterProvider>
                                                            <CalendarProvider>
                                                                <PitchEventCalendar />
                                                            </CalendarProvider>
                                                        </EventFilterProvider>
                                                    </QueryBasedPitchProvider>
                                                }
                                            />

                                            {/* WEATHER */}
                                            <Route
                                                exact
                                                path='/weather'
                                                element={
                                                    <QueryBasedPitchProvider>
                                                        <QueryBasedVenueProvider >
                                                            <Weather />
                                                        </QueryBasedVenueProvider>
                                                    </QueryBasedPitchProvider>
                                                }
                                            />

                                            {/* ANALYSES */}
                                            <Route
                                                exact
                                                path='/analyses'
                                                element={<AnalysisOverview />}
                                            />

                                            {/* CREATE ANALYSIS */}
                                            <Route
                                                exact
                                                path='/create-analysis/:pitchId?'
                                                element={<AnalysisCreate />}
                                            />

                                            {/* catch noMatch */}
                                            <Route path={'*'} element={<Navigate to='/' />} />
                                        </ >
                                    </Route>
                                </Routes>
                            }
                        </Router>
                    </TimezoneProvider>
                </IntlProvider>
            </DndProvider>
        </GenericErrorBoundary>
    )
}