import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { css } from '@emotion/react'
//data
import useRawApiState from "../../hooks/useRawApiState"
import useLdjsonApi from "../../hooks/useLdjsonApi"
import Pitch from "../../context/Pitch"
import PitchListContext from "../../context/PitchList"
import EventFilterContext from "../../context/EventFilter"
//utilities
import { getMonth, getYear, format, getUnixTime } from "date-fns"
import { groupBy, countBy } from "lodash"
//components
import PageTitle from "../../components/_general/PageTitle"
import LoaderText from "../../components/_general/LoaderText"
import MonthlyCalendar from "../../components/_general/MonthlyCalendar"
import ContainerDisabler from "../../components/_general/ContainerDisabler"
import MonthBrowser from "../../components/_control/MonthBrowser"
import EventTypeIcon from "../../components/events/EventTypeIcon"
import EventTypeList from "../../components/events/EventTypeList"
import DaySummaryModal from "../../components/events/DaySummaryModal"
import DayDropZone from "../../components/events/DayDropZone"
import FancyDropdown from "../../components/_control/FancyDropdown"
import Modal from "../../components/_layout/Modal"
import EventWrapper from "../modals/event-forms/EventWrapper"
import { NavLink, useSearchParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
//assets
import { colors } from "../../style/vars"
import filterImg from '../../assets/icons/filter.svg'
import { MTM_LIST_TYPES, MTM_EVENT_TYPES, MTM_VARIABLES } from "../../utils/matomo"
import useTracker from "../../hooks/useTracker"
import CalendarContext from "../../context/Calendar"
import { FormattedMessage, useIntl } from "react-intl"
import useBridge from "../../hooks/useBridge"
import { toZonedTime, fromZonedTime } from "date-fns-tz"

function PitchEventCalendarDev({ ...props }) {

    const { data: eventTypes, loading: loadingEventTypes } = useBridge('/api/current/frontend-bridge/event-types')

    const { formatMessage } = useIntl()

    const [, setSearchParams] = useSearchParams()

    const track = useTracker()

    const { pitches } = useContext(PitchListContext)
    const { setSelectedDay } = useContext(CalendarContext)
    const [pitch = {}] = useContext(Pitch)
    const [month, setMonth] = useState(getMonth(new Date()))
    const [year, setYear] = useState(getYear(new Date()))

    const pitchFilterOptions = useMemo(() => {
        return pitches?.map(({ id, name }) => ({
            value: id,
            label: name,
        }))
    }, [pitches])

    const onChangePitches = useCallback((pitchId) => {
        const label = pitchFilterOptions.find(pitchOption => pitchOption.value === pitchId)?.label
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['pitch-select'],
            [MTM_VARIABLES['list-value']]: label
        })
        setSearchParams({ pitch: pitchId })
    }, [pitchFilterOptions])

    const [fromUnix, toUnix, timeZone] = useMemo(() => {
        const timeZone = pitch?.venue?.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
        // time for pitch
        return [
            getUnixTime(fromZonedTime(new Date(year, month, 1), timeZone)),
            getUnixTime(fromZonedTime(new Date(year, month + 1, 1), timeZone)),
            timeZone,
        ]
    }, [pitch, month, year])

    const dateToKey = useCallback((date) => {
        return format(toZonedTime(date, timeZone), "y-M-d")
    }, [timeZone])

    const { data: events, isBusy = false } = useRawApiState(
        `/api/current/pitches/${pitch?.id}/events`,
        {
            from: fromUnix,
            to: toUnix,
        },
        [pitch?.id, fromUnix, toUnix]
    )

    const [fakeEvents, setFakeEvents] = useState([])
    const [allEvents, setAllEvents] = useState([])

    // if events change (eg. we got new data from the api), drop addedEvents
    useEffect(() => {
        setFakeEvents([])
        if (events) setAllEvents(events)
    }, [events])

    const eventsPerDay = useMemo(() => {
        const combinedEvents = allEvents ? [...allEvents.sort((a, b) => a.id - b.id), ...fakeEvents] : {}
        return groupBy(
            combinedEvents,
            ({ timestamp }) => dateToKey(new Date(timestamp * 1000))
        )
    }, [allEvents, fakeEvents])

    const [selectedEventType, setSelectedEventType] = useState()
    const [selectedEvent, setSelectedEvent] = useState()
    const [daySummaryData, setDaySummaryData] = useState([])
    const [filter, setFilter] = useContext(EventFilterContext)

    const handleModalSubmit = useCallback((success, data) => {
        // close modal
        setSelectedEventType(null)
        setSelectedEvent(null)
        // clean up fake event
        setFakeEvents(current => current?.slice(0, -1) ?? [])
        if (success) {
            setAllEvents(allEvents => {
                if (allEvents.find(e => e.id === data.id)) { // PUT
                    // track calendar event update
                    track({
                        'event': MTM_EVENT_TYPES["update-event"],
                        [MTM_VARIABLES["event-type"]]: data.type
                    })
                    // Replacing the updated object
                    const removedOldEvent = allEvents.filter(e => e.id !== data.id)
                    return [...removedOldEvent, data]
                } else { // POST
                    // track calendar event creation
                    track({
                        'event': MTM_EVENT_TYPES["create-event"],
                        [MTM_VARIABLES["event-type"]]: data.type
                    })
                    // adding
                    return [...allEvents, data]
                }

            })
            if (daySummaryData) {
                // Updating the event of the selected day
                setDaySummaryData(daySummaryData => {
                    const nodeEvent = daySummaryData.find(n => n.id === data.id)
                    const pos = daySummaryData.indexOf(nodeEvent)
                    const replacedNode = [...daySummaryData]
                    replacedNode[pos] = data
                    return replacedNode
                })
            }
        }
    })

    const getFormHeader = useCallback(() => {
        if (selectedEvent) return `${formatMessage({ id: 'edit' })} / ${eventTypes?.find(x => x.entityShortName === selectedEvent.type).entityShortNameLocalised}`
        return `${formatMessage({ id: 'add' })} / ${selectedEventType.entityShortNameLocalised}`
    }, [selectedEvent, selectedEventType, eventTypes])

    const { del } = useLdjsonApi()
    const deleteEvent = useCallback(async event => {
        const { data, error } = await del(`${event["@id"]}`)
        if (!error) {
            track({
                'event': MTM_EVENT_TYPES["delete-event"],
                [MTM_VARIABLES["event-type"]]: event.type
            })
            setDaySummaryData(daySummaryData.filter(n => n.id !== event.id))
            setAllEvents(allEvents => allEvents.filter(e => e.id !== event.id))
        }
        return { error, data }
    }, [allEvents, daySummaryData])

    const handleDrop = useCallback(async (date, { eventType }) => {
        if (pitch && eventType) {
            // immediatly add fake event, for dat sweet UX
            const fakeEvent = {
                name: "name TBD",
                timestamp: getUnixTime(date),///
                pitch: pitch["@id"],
                type: eventType.entityShortName,
                fakeId: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER) // extremely tiny chance of ID collisions, we'll risk it
            }
            setFakeEvents(current => [...current, fakeEvent])
            //data for input modal
            setSelectedDay(date)
            setSelectedEventType(eventType)
        }
    }, [pitch])

    const handleClearFilter = useCallback((e) => {
        e.stopPropagation()
        setFilter([])
        track({
            'event': MTM_EVENT_TYPES["unfilter-calendar"],
        })
    }, [])

    return (
        <>
            <div css={css`display: flex; flex-direction: column; align-items: stretch; height: 100%;`}
                {...props}
            >
                <PageTitle css={css`margin-bottom: 0.25em;`}>
                    <span>
                        {pitch.id ? (
                            isBusy ?
                                <LoaderText subtle />
                                :
                                <>
                                    <NavLink
                                        to={!!pitch.club?.id ? `/club/${pitch.club?.id}` : undefined}
                                        css={css`
                                            color: inherit;
                                            cursor: ${!!pitch.club?.id ? 'pointer' : 'unset'};
                                            pointer-events: ${!!pitch.club?.id ? 'unset' : 'none'};
                                        `}
                                    >
                                        {pitch.club?.name || formatMessage({id: 'noClub'})}
                                    </NavLink>
                                    {' / '}
                                    <NavLink to={`/pitch/${pitch.id}`} css={css`color: inherit;`}>
                                        {pitch.name}
                                    </NavLink>
                                </>
                        ) :
                            <LoaderText subtle>
                                <FormattedMessage id='noPitchSelected' />
                            </LoaderText>
                        }
                    </span>
                    <span css={css`color: ${colors.main1};`}>
                        {" "} / <FormattedMessage id='events' />
                    </span>
                </PageTitle>
                <div css={css`display: flex; align-items: center;`}>
                    <div css={css`
                        display: flex; 
                        align-items: center; 
                        justify-content: center;
                        flex-grow: 1; 
                        position: relative;
                    `}>
                        <ContainerDisabler disable={!pitch?.id} />
                        <MonthBrowser
                            month={month}
                            year={year}
                            setMonth={setMonth}
                            setYear={setYear}
                        />
                    </div>
                    <FancyDropdown
                        options={[
                            {
                                value: "",
                                label: formatMessage({ id: 'none' })
                            },
                            ...pitchFilterOptions
                        ]}
                        placeholder={formatMessage({ id: 'selectAPitch' })}
                        css={styles.pitchSelector}
                        value={pitchFilterOptions?.find(({ value }) => (value === pitch?.id))}
                        onChange={({ value }) => onChangePitches(value)}
                    />
                </div>
                <div css={[
                    css`display: flex; flex-basis: 0; flex-grow: 1;`,
                    styles.calendarAndEventsWrapper
                ]}>
                    <ContainerDisabler disable={!pitch?.id} />
                    <MonthlyCalendar
                        css={styles.calendar}
                        month={month}
                        year={year}
                        dateKeyMapper={dateToKey}
                        busy={isBusy}
                        onDayClick={date => {
                            const dateKey = dateToKey(date)
                            if (dateKey in eventsPerDay) {
                                setDaySummaryData(eventsPerDay[dateKey])
                            }
                        }}
                        renderDay={(date, node) => (
                            <DayDropZone
                                onDrop={event => handleDrop(date, event)}
                                date={date}  
                                key={`dropzone-${getUnixTime(date)}`}
                            >
                                {node}
                            </DayDropZone>
                        )}>
                        {Object.entries(eventsPerDay)
                            .map(
                                ([key, events]) => ({
                                    key,
                                    node: Object.entries(countBy(events, 'type'))
                                        .map(([eventType, count]) => {
                                            if ((filter?.length === 0) || filter.includes(eventType)) {
                                                return <EventTypeIcon
                                                    eventType={eventType}
                                                    counter={(count >= 2) ? count : null}
                                                    title={eventTypes?.find(x => x.entityShortName === eventType)?.entityShortNameLocalised}
                                                    css={css`cursor: pointer;`}
                                                    key={`${eventType}-${key}`}
                                                />
                                            } else {
                                                return <div title={formatMessage({ id: 'eventsOmmittedByFilter' })}>
                                                    <ReactSVG
                                                        src={filterImg}
                                                        css={styles.hiddenDataNotifier}
                                                        onClick={handleClearFilter}
                                                    />
                                                </div>
                                            }
                                        })
                                })
                            )}
                    </MonthlyCalendar>
                    <EventTypeList />
                </div>
            </div >
            {daySummaryData?.[0] &&
                <DaySummaryModal
                    daySummaryData={daySummaryData}
                    setDaySummaryData={setDaySummaryData}
                    deleteEvent={deleteEvent}
                    setSelectedEvent={setSelectedEvent}
                />
            }

            {(selectedEventType || selectedEvent) && !loadingEventTypes &&
                <Modal
                    header={getFormHeader()}
                    onClickClose={() => {
                        setSelectedEventType(null)
                        setSelectedEvent(null)
                        setFakeEvents(current => current?.slice(0, -1) ?? [])
                    }}
                    css={css`width: auto; overflow: visible;`}>
                    <EventWrapper
                        eventType={
                            selectedEventType
                                ? selectedEventType
                                : selectedEvent.type
                        }
                        event={selectedEvent}
                        pitch={pitch}
                        onPostRequest={(success, data) => handleModalSubmit(success, data)}
                    />
                </Modal>
            }
        </>
    );
}

export default PitchEventCalendarDev;

const styles = {
    pitchSelector: css`
        width: 17.5em;
        border-radius: 5px;
        border: 2px solid #394047;
        background-color: #2F353B;
        z-index: 2000;

        >div {
            padding: 1em;
        }
    `,
    calendarAndEventsWrapper: css`
        height: 70vh;
        margin-top: 1em;
        userSelect: none;
        position: relative;
    `,
    calendar: css`
        flex-grow: 1;
        margin-right: 1em;
        height: 100%;
    `,
    hiddenDataNotifier: css`
        fill: ${colors.liquid};
        position: absolute;
        top: 0.2em;
        right: 0.2em;
        width: 1em;
        height: 1em;
        border: solid 1px ${colors.liquid};
        cursor: pointer;

        &:hover {
            fill: ${colors.soft};
        }
    `
}
