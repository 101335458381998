import React from 'react'
import { css, jsx } from '@emotion/react'

export default function OptionsSelector({ label, optionsObject, state, setState, disabled, ...props }) {

    const ctaList =
        optionsObject && Object.entries(optionsObject).map(
            ([key, value]) => (
                <button
                    css={{
                        marginRight: 10,
                        width: props.btnWidth ?? 150,
                        height: 'auto',
                        backgroundColor: '#2D3339',
                        border: key === state ? '2px solid #75FBC9' : '2px solid #3A424A',
                        padding: '8px 16px',
                        borderRadius: '5px',
                        margin: '8px 16px 0 0',
                        color: key === state ? '#75FBC9' : '#CFD6D8',
                        outline: 'none',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                        height: props.btnHeight,
                        opacity: disabled ? 0.5 : 1
                    }}
                    key={key}
                    value={key}
                    disabled={disabled}
                    onClick={(e) => setState(e.target.value)}
                >
                    {value}
                </button>
            )
        )

    return <div css={{ marginBottom: 20 }}>
        <h3 style={{ marginBottom: 8 }}>{label}</h3>
        <div css={css`display: flex; flex-wrap: wrap;`}>
            {ctaList}
        </div>
    </div >
}