import React, { useCallback, useState, Fragment, useMemo, useEffect, useContext } from 'react'
import useLdjsonApi from '../../../hooks/useLdjsonApi'
import PropTypes from 'prop-types'
import { getUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import Selector from '../../../components/_control/OptionsSelector'
import NumberScroller from '../../../components/_control/NumberScroller'
import FormLoader from './FormLoader'
import useBridge from '../../../hooks/useBridge'
import EventDateTimePicker from '../../../components/_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import PitchContext from '../../../context/Pitch'
import { colors } from '../../../style/vars'

const amountMin = 0
const amountMax = 20000

export default function FertiliserForm({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [fertiliserType, setFertiliserType] = useState('liquid')
    const [additionals, setAdditionals] = useState([])
    const [amount, setAmount] = useState(0)
    const [unit, setUnit] = useState('litres')
    const [gravity, setGravity] = useState(1)
    const [productN, setProductN] = useState(0)
    const [productP, setProductP] = useState(0)
    const [productK, setProductK] = useState(0)
    //other
    const { data: fertiliserTypesOptions, loading: isTypesBusy } = useBridge(`/api/current/frontend-bridge/fertiliser-types`)
    const { data: additionalsData, loading: isAdditionalsBusy } = useBridge(`/api/current/frontend-bridge/fertiliser-additional-nutritions`)
    const { data: unitsOptions, loading: isUnitsBusy } = useBridge(`/api/current/frontend-bridge/units`)
    const [pitch] = useContext(PitchContext)

    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/fertilising-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setFertiliserType(data.fertilisingType)
                setAdditionals(data.additionalNutrition)
                setAmount(data.amount)
                setUnit(data.unit)
                setGravity(data.gravity)
                setProductN(data.productNitrogen)
                setProductP(data.productPhosphorus)
                setProductK(data.productPotassium)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // derived data
    const isLiquid = useMemo(() => {
        return fertiliserType === 'liquid'
    }, [fertiliserType])

    const calculateResult = useCallback((product) => (
        isLiquid && gravity ?
            amount * gravity * product / 100
            :
            amount * product / 100
    ), [isLiquid, amount, gravity])

    const resultN = useMemo(() => calculateResult(productN), [productN, calculateResult])
    const resultP = useMemo(() => calculateResult(productP), [productP, calculateResult])
    const resultK = useMemo(() => calculateResult(productK), [productK, calculateResult])

    const handleUnitChange = useCallback((val) => {
        setUnit(isLiquid ? unitsOptions.litres : val)
    }, [isLiquid, unitsOptions])

    const HandleFertilizerChange = useCallback((val) => {
        if (val === 'liquid') setUnit('litres')
        setFertiliserType(val)
    }, [])

    const putAdditionals = useCallback((type) => {
        setAdditionals(prev => {
            return prev.includes(type) ?
                prev.filter(el => el != type)
                :
                [...prev, type]
        })
    }, [])

    // when the required field are set the form is valid and will submit.
    const validateAndSubmit = useCallback(async () => {

        if (!fertiliserType) return
        if (!Array.isArray(additionals)) return
        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)
        const payload = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            amount: parseInt(amount),
            unit: unit,
            fertilisingType: fertiliserType,
            gravity: isLiquid ? parseFloat(gravity) : null,
            productNitrogen: parseInt(productN),
            productPhosphorus: parseInt(productP),
            productPotassium: parseInt(productK),
            additionalNutrition: additionals,
        }

        if (event) {
            const { data, error } = await put(`${event['@id']}`, { body: payload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const newPayload = {
                ...payload,
                name: `Fertiliser event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [pitch, amount, unit, fertiliserType, gravity, productP, productN, productK, additionals, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => (
        isTypesBusy || isAdditionalsBusy || isLoading || isUnitsBusy
    ), [isTypesBusy, isAdditionalsBusy, isLoading, isUnitsBusy])

    return (<>
        {isBusy ? <FormLoader /> : <>
            <EventDateTimePicker
                unixDate={selectedDateTime}
                setUnixDate={setSelectedDateTime}
            />
            <Selector
                label={formatMessage({ id: 'fertiliserType' })}
                optionsObject={fertiliserTypesOptions}
                setState={HandleFertilizerChange}
                state={fertiliserType} />

            <div css={styles.row} >
                <NumberScroller
                    label={formatMessage({ id: 'amount' })}
                    minVal={amountMin}
                    maxVal={amountMax}
                    setState={setAmount}
                    state={amount}
                />
                <Selector
                    state={unit}
                    setState={handleUnitChange}
                    optionsObject={unitsOptions}
                    btnWidth={100}
                    disabled={isLiquid}
                />
                {
                    isLiquid && <div>
                        <NumberScroller
                            label={formatMessage({ id: 'specificGravity' })}
                            minVal={1}
                            maxVal={2}
                            setState={setGravity}
                            state={gravity}
                            step={0.01}
                        />
                    </div>}
                <NumberScroller
                    label={formatMessage({ id: 'timeTaken' })}
                    minVal={0}
                    maxVal={24}
                    state={timeTaken}
                    unit={formatMessage({ id: 'hours' })}
                    step={0.5}
                    setState={setTimeTaken}
                />

            </div>

            <h3>
                <FormattedMessage id='product' />
            </h3>
            <div css={styles.row}>
                <div css={styles.product}>
                    <NumberScroller
                        label="N"
                        minVal={0}
                        maxVal={100}
                        setState={setProductN}
                        state={productN}
                    />
                </div>
                <div css={styles.product}>
                    <NumberScroller
                        label="P"
                        minVal={0}
                        maxVal={100}
                        setState={setProductP}
                        state={productP}
                    />
                </div>
                <div css={styles.product}>
                    <NumberScroller
                        label="K"
                        minVal={0}
                        maxVal={100}
                        setState={setProductK}
                        state={productK}
                    />
                </div>
                <div css={styles.totals}>
                    <div><FormattedMessage id='nitrogen' />: {resultN.toFixed(2)}</div>
                    <div><FormattedMessage id='phosphorus' />: {resultP.toFixed(2)}</div>
                    <div><FormattedMessage id='potassium' />: {resultK.toFixed(2)}</div>
                </div>
            </div>
            {additionalsData && <>
                <h3 style={{ margin: '16px 0' }}>
                    <FormattedMessage id='additionalNutrition' />
                </h3>
                {Object.keys(additionalsData).map((type) =>
                    <Fragment key={type}>
                        <button
                            onClick={() => putAdditionals(type)}
                            css={[styles.additional, css`
                                border: ${additionals.includes(type) ? '2px solid #75FBC9' : '2px solid #3A424A'};
                                color: ${additionals.includes(type) ? '#75FBC9' : colors.soft};
                            `]}>
                            {additionalsData[type]}
                        </ button>
                    </ Fragment>
                )}
            </>}
        </>}
        <div style={{ marginTop: '40px' }}>
            <button
                onClick={() => validateAndSubmit()}
                type='button'
                className="btn"
                disabled={isBusy}
            >
                <FormattedMessage id='save' />
            </button>
        </div>
    </>)
}

FertiliserForm.propTypes = {
    pitch: PropTypes.object,
    eventType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    day: PropTypes.object,
    onPostRequest: PropTypes.func,
}

const styles = {
    row: {
        display: 'flex',
        margin: '1em 0',
        alignItems: "center",
        justifyContent: "start",
        flexWrap: 'wrap',
        gap: '2em'
    },
    additional: css`
        background-color: #2D3339;
        padding: 8px 16px;
        border-radius: 5px;
        margin-right: 16px;
        margin-bottom: 8px;
        outline: none;
        cursor: pointer;
        text-transform: capitalize;
    `,
    product: css`
        background-color: #26292C;
        border: 1px solid #292C2F;
        margin-right: 1em;
        text-align: center;
        display: flex;
        justify-content: center;
        padding-top: 1em;
        border-radius: 3px;

        label {
            color: #8A9AA8;
            font-weight: bold;
            font-size: 1.5em;
        }
    `,
    totals: css`
        > div {
            margin: 1em 0;
        }
    `
}
