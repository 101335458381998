import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors } from '../../style/vars'


const Input = props => (
    <input
        css={css`
            background-color: ${colors.white};
            font-size: 0.9375em;
            padding: 1.25em;
            &::placeholder {
                color: ${rgba(colors.solid, 0.5)};
            }
            outline: none;
            border: none;
        `}
        {...props}
    />
)

function TextField({ className, type = 'text', ...props }) {
    return (
        <Input
            className={className}
            type={type}
            {...props}
        />
    )
}

TextField.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
}

export default TextField
