import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/react'
import { rgba } from 'polished'
import { colors, timings } from '../../style/vars'
import Loader from '../_general/Loader'
import { NavLink } from 'react-router-dom'

const cc = (obj) => {
    const arr = []
    for (const [key, value] of Object.entries(obj)) {
        if (!!value) arr.push(key)
    }
    return arr.join(' ')
}

const style = {
    table: (clickable, light) => css`
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 0.75em;
        margin-top: -0.75em; // to counter-act border-spacing
        margin-bottom: -0.75em;
        font-size: 0.875em;

        td {
            vertical-align: middle;
        }

        tbody td > a, tbody td > div {
            color: unset;
            padding: 1em 1.125em;
            display: flex !important; // override serialized styles from emotion react
            align-items: center; 
            height: 100%;
        }

        tbody tr {
            transition: all ${timings.fast} ease;
            box-shadow: 0 0.25em 0.75em 0 rgba(0,0,0,0.3);
            ${clickable && !light ? `
                opacity: 0.8;
                background-color: ${colors.stuff};
            ` : ''}

            ${clickable ? `
                cursor: pointer;
                &:hover {
                    transition: all ${timings.snappy} ease;
                    opacity: 1;
                    box-shadow: 0 0.75em 1.5em 0 ${rgba(colors.black, 0.5)};
                    transform: translateY(-0.125em);
                }
            ` : ''}
        }
        tbody tr td {
            padding: 0;
            color: ${colors.white};
            overflow: hidden;
            margin: 0;
            height: 6em;
            position: relative;
            border-right: 1px solid ${rgba(colors.white, 0.1)};
            background-color: ${colors.solid};
            &:first-of-type {
                border-top-left-radius: 0.25rem;
                border-bottom-left-radius: 0.25rem;
            }
            &:last-of-type {
                border-top-right-radius: 0.25rem;
                border-bottom-right-radius: 0.25rem;
                border-right: none;
            }

            // luckily tables handle these beautifully
            &.wide {
                width: 100%;
            }
            &.shrink {
                width: 1px;
                text-align: center;
            }
        }
    `,
    headItem: (shrink, sortable, isSorting, isAsc) => css`
        padding: 1em 1.125em 0.2em 1.125em;
        transition: all ${timings.fast} ease;
        color: ${colors.white};
        opacity: 0.35;
        white-space: nowrap;

        .arrow {
            display: none;
            font-size: 0.75em;
            position: relative;
            top: 0.125em;
            transition: transform ${timings.smooth} ease;
        }
        ${shrink && `
            text-align: center;
            padding-left: 0.75em;
            padding-right: 0.75em;
        `}
        ${sortable && `
            cursor: pointer;
            user-select: none;
            .arrow {
                margin-left: 0.5em;
                display: inline-block;
                opacity: 0.75;
            }

            &:hover {
                transition: all ${timings.snappy} ease;
                color: ${colors.main1};
                opacity: 1;
            }
            ${isSorting && `
                opacity: 1;
                .arrow {
                    opacity: 1;
                    transform: rotate(180deg);
                }
                ${isAsc && `
                    .arrow {
                        transform: none;
                    }
                `}
            `}
        `}
    `,
}

function Table({
    data,
    columns,
    rowKey,
    onRowClick,
    getRowHref,
    sortOn,
    sortDirection: initialSortDirection = 'asc',
    showHeader = true,
    stickyHeader = false,
    headerBg,
    loading = false,
    fallback,
    light = false,
    ...props
}) {
    const [sortKey, setSortKey] = useState(sortOn)
    const [sortDirection, setSortDirection] = useState(initialSortDirection)

    const handleSort = useCallback((sortColumn) => {
        if (sortKey === sortColumn) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
        } else {
            setSortKey(sortColumn)
            setSortDirection('asc')
        }
    })

    const rows = useMemo(() => (
        sortDirection === 'asc'
            ? data.sort((a, b) => (a[sortKey] < b[sortKey] ? -1 : 1))
            : data.sort((a, b) => (a[sortKey] > b[sortKey] ? -1 : 1))
    ), [data, sortKey, sortDirection])

    const hasData = rows.length > 0
    const clickable = hasData && typeof getRowHref === 'function'

    return (
        <div {...props}>
            <table css={style.table(clickable, light)}>
                {showHeader &&
                    <thead css={stickyHeader && css`
                        position: sticky; 
                        top: -1em; // stupid hack for safari, we compensate this with thead item padding
                        z-index: 1; 
                        background: ${headerBg};
                    `}>
                        <tr>
                            {columns.map((column, i) => {
                                const isSorting = sortKey === column.key
                                const isAsc = sortDirection === 'asc'
                                return (
                                    <td
                                        key={column.key || i}
                                        css={style.headItem(column.shrink, column.sortable, isSorting, isAsc)}
                                        onClick={column.sortable ? () => handleSort(column.key) : undefined}
                                    >
                                        <div css={css`
                                            display: flex;
                                            justify-content: ${column.shrink ? 'center' : 'start'};
                                            align-items: center;
                                        `}>
                                            <span style={{ whiteSpace: column.headerWrap ? 'normal' : 'nowrap' }}>{column.header}</span>
                                            <span className='arrow'>&#x25BE;</span>
                                        </div>
                                    </td>
                                )
                            })}
                        </tr>
                    </thead>
                }
                <tbody>
                    {hasData && rows.map((row, i) => (
                        <tr key={rowKey(row) || i}>
                            {columns.map((column, i) => (
                                <td key={column.key || i} className={cc({ wide: column.wide, shrink: column.shrink })}>
                                    {clickable ?
                                        <NavLink to={getRowHref?.(row)}>
                                            {column.renderData ? column.renderData(row) : row[column.key]}
                                        </NavLink>
                                        :
                                        <div>
                                            {column.renderData ? column.renderData(row) : row[column.key]}
                                        </div>
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                    {(!loading && !hasData && fallback) &&
                        <tr>
                            {columns.map((column, i) => (
                                <td key={column.key || i} className={cc({ wide: column.wide, shrink: column.shrink })}>
                                    {i === 0 &&
                                        <div>
                                            {fallback}
                                        </div>
                                    }
                                </td>
                            ))}
                        </tr>
                    }
                    {loading &&
                        <tr>
                            {columns.map((column, i) => (
                                <td key={column.key || i} className={cc({ wide: column.wide, shrink: column.shrink })}>
                                    {i === 0 &&
                                        <div>
                                            <Loader size='1.5em' />
                                        </div>
                                    }
                                </td>
                            ))}
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any,
        header: PropTypes.node,
        headerWrap: PropTypes.bool,
        renderHeader: PropTypes.string,
        renderData: PropTypes.func,
        wide: PropTypes.bool,
        shrink: PropTypes.bool,
        sortable: PropTypes.bool,
    })).isRequired,
    rowKey: PropTypes.func.isRequired,
    getRowHref: PropTypes.func,
    sortOn: PropTypes.string,
    sortDirection: PropTypes.oneOf(['asc', 'desc']),
    showHeader: PropTypes.bool,
    loading: PropTypes.bool,
    fallback: PropTypes.node,
    light: PropTypes.bool,
    stickyHeader: PropTypes.bool,
    headerBg: PropTypes.string,
}

export default Table
