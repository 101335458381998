import React from 'react'
import Loader from '../../../components/_general/Loader'
import { css } from '@emotion/react'

export default function FormLoader({ ...props }) {

    return (
        <div css={css`
            display: grid;
            place-items: center;
            width: 400px;
            min-width: 100%;
            height: 20em;
            max-height: 90svh;
        `}>
            <Loader
                size='3em'
            />
        </div>
    )
}