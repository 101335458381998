import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react'
import Pitch from '../../context/Pitch'
import PageTitle from '../../components/_general/PageTitle'
import { css } from '@emotion/react'
import { colors, fonts } from '../../style/vars'
import { useSearchParams } from 'react-router-dom'
import FancyDropdown from '../../components/_control/FancyDropdown'
import LiveWeather from './liveWeather'
import WeatherForecast from './weatherForecast'
import WeatherHistory from './WeatherHistory'
import useRawV2 from '../../hooks/useRawV2'
import VenueContext from '../../context/Venue'
import VenueWeather from '../../components/weather/VenueWeather'
import Loader from '../../components/_general/Loader'
import { MTM_LIST_TYPES, MTM_EVENT_TYPES, MTM_VARIABLES } from '../../utils/matomo'
import useTracker from '../../hooks/useTracker'
import { FormattedMessage, useIntl } from 'react-intl'

// shows all venue forecasts with tommorow.io
// shows weather station data based on a selected pitch
// default to the first venue, and the first pitch containing a station

function Weather({ ...props }) {

    const track = useTracker()

    const [params, setSearchParams] = useSearchParams()
    const pitchParamActive = !!params.get('pitch')
    const venueParamActive = !!params.get('venue')

    const [venue] = useContext(VenueContext)
    const [venues, { busy: venuesBusy }] = useRawV2('/api/current/venues?pagination=false')

    const venueOptions = useMemo(() => (
        venues?.['hydra:member']?.map(venue => ({ label: venue.name, value: venue.id }))
        || []
    ), [venues])

    const onChangeVenue = useCallback((id) => {
        const label = venueOptions.find(venueOption => venueOption.value === id)?.label
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['venue-select'],
            [MTM_VARIABLES['list-value']]: label
        })
        setSearchParams(current => {
            const pitchId = Number(current.get('pitch')) || undefined
            return pitchId ? { venue: id, pitch: pitchId } : { venue: id }
        })
    }, [setSearchParams, venueOptions])

    // toggle to first in list after venues load, unless the page already had a query
    useEffect(() => {
        if (venueParamActive) return
        const firstIdInList = venues?.['hydra:member']?.[0]?.id
        firstIdInList && onChangeVenue(firstIdInList)
    }, [venues])

    const [pitch] = useContext(Pitch)

    const weatherFlowStation = useMemo(() => (
        pitch?.weatherFlowStations?.[0]
    ), [pitch])

    const [pitches, { busy: pitchesBusy }] = useRawV2('/api/current/pitches?pagination=false')

    const pitchOptions = useMemo(() => (
        pitches?.['hydra:member']
            ?.filter(p => p.weatherFlowStations?.length > 0)
            ?.map(({ id, name, weatherFlowStations }) => ({
                label: name,
                value: id,
                highlighted: weatherFlowStations?.length > 0
            }))
        || []
    ), [pitches])

    // toggle to first in list after pitches load, unless the page already had a query
    useEffect(() => {
        if (pitchParamActive) return
        const firstIdInList = pitchOptions?.[0]?.value
        firstIdInList && onChangePitch(firstIdInList)
    }, [pitchOptions])

    const onChangePitch = useCallback((id) => {
        const label = pitchOptions.find(pitchOption => pitchOption.value === id)?.label
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['pitch-select'],
            [MTM_VARIABLES['list-value']]: label
        })
        setSearchParams(current => {
            const venueId = Number(current.get('venue')) || undefined
            return venueId ? { pitch: id, venue: venueId } : { pitch: id }
        })
    }, [pitchOptions, setSearchParams])

    const tabs = ["Live", "History", "Forecast"]
    const [activeTab, setActiveTab] = useState(tabs[0])

    const handleTabChange = useCallback((tab) => {
        track({
            'event': MTM_EVENT_TYPES['option-select'],
            [MTM_VARIABLES['list-type']]: MTM_LIST_TYPES['tab-select'],
            [MTM_VARIABLES['list-value']]: tab
        })
        setActiveTab(tab)
    }, [])

    const { formatMessage } = useIntl()

    return (
        <div css={css`display: flex; flex-direction: column; align-items: strecth; flex-grow: 1;`}>
            <PageTitle css={{ marginBottom: ".25em" }}>
                <span css={{ color: colors.main1 }}>
                    <FormattedMessage id='weather' />
                </span>
            </PageTitle>

            {/* tomorrow.io */}
            <h2 css={style.subtitle}>
                <FormattedMessage id='venueForecast' />
            </h2>
            <div css={css`display: flex`}>
                <FancyDropdown
                    options={(venueOptions.length > 0) ?
                        [...venueOptions]
                        : [{ label: formatMessage({ id: 'noVenuesFound' }), value: '' }]
                    }
                    css={[style.dropdown, css`align-self: start; margin-bottom: 0.5em;`]}
                    onChange={({ value }) => onChangeVenue(value)}
                    value={venueOptions?.find(({ value }) => (value === venue?.id)) || formatMessage({ id: 'select' })}
                />
                {venuesBusy && <Loader size={'25px'} css={css`align-self: center;`} />}
            </div>
            <VenueWeather venue={venue} />

            {/* tempest weather station and api */}
            <h2 css={[style.subtitle, css`margin-top: 1em;`]}>
                <FormattedMessage id='weatherStation' />
            </h2>
            <div css={style.stationControls}>
                <FancyDropdown
                    options={pitchOptions.length > 0 ?
                        [...pitchOptions]
                        : [{ label: formatMessage({ id: 'none' }), value: '' }]
                    }
                    css={style.dropdown}
                    onChange={({ value }) => onChangePitch(value)}
                    value={pitchOptions?.find(({ value }) => (value === pitch?.id)) || formatMessage({ id: 'select' })}
                />
                <div css={css`
                    display: grid;
                    flex-grow: 1;
                    grid-template-columns: repeat(${tabs.length}, 1fr);
                    gap: 0.3em;
                    min-height: 3em;
                `}>
                    {tabs.map(tab => (
                        <div css={style.tabBar(activeTab === tab)}
                            onClick={(() => handleTabChange(tab))}
                        >
                            {formatMessage({ id: tab.toLowerCase() })}
                            {activeTab === tab &&
                                <div css={style.tab} />
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div css={css`height: 45em; width: 100%; display: grid; position: relative;`}> {/* prevent layout shift */}
                {pitchesBusy ?
                    <Loader size={'25px'} css={css`justify-self: center;`} />
                    :
                    weatherFlowStation &&
                        activeTab === tabs[0] ? <LiveWeather stationId={weatherFlowStation?.stationId} />
                        : activeTab === tabs[1] ? <WeatherHistory stationId={weatherFlowStation?.stationId} />
                            : activeTab === tabs[2] ? <WeatherForecast stationId={weatherFlowStation?.stationId} />
                                : <div css={style.placeholder}>
                                    {pitch?.name ?
                                        `${pitch.name} has no station data`.toUpperCase()
                                        : formatMessage({ id: 'pleaseSelectAPitch' }).toUpperCase()
                                    }
                                </div>
                }
            </div>
        </div>
    )
}

export default Weather

const style = {
    dropdown: css`
        width: 19em;
        margin-right: 1em;
        border-radius: 5px;
        border: 2px solid #394047;
        background-color: #2F353B;
        place-self: center;

        > div {
            padding: 1em;
        }
    `,
    stationControls: css`
        display: flex; 
        gap: 0.5em;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 0.5em;
    `,
    subtitle: css`
        color: ${colors.white};
        font-size: 1.7em;
        margin: 0.4em 0;
    `,
    placeholder: css`
        width: 100%;
        display: grid;
        place-items: center;
        font-family: ${fonts.special};
        font-size: 2em;
        height: 6em;
        color: ${colors.eventLight};
    `,
    tabBar: (isActive) => css`
        display: grid;
        place-items: center;
        background: ${colors.eventLight};
        color: ${isActive ? colors.white : colors.soft};
        font-family: ${fonts.main};
        border-radius: 5px;
        cursor: pointer;
        padding: 0 1em;
        user-select: none;
        -webkit-user-select: none;
        position: relative;
        overflow: hidden;
        font-size: 1.2em;

        &:hover {
            color: ${colors.main1};
        }
    `,
    tab: css`
        position: absolute;
        bottom: 0;
        height: 3px;
        width: 100%;
        background: ${colors.main1};
    `
} 