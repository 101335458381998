import React, { useState, useCallback } from 'react'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import Cta from '../../components/_control/Cta'
import { FormattedMessage, useIntl } from 'react-intl'

const style = {
    cont: css`
        display: flex;
        justify-content: center;
        align-items: center;
    `,
    wrapper: css`
        background-color: ${colors.substance};
        min-height: 3em;
        margin-top: 1em;
        padding: 2em 1em;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        border: 2px solid ${colors.eventDark};
    `,
    form: css`
        margin-top: 2em;
        display: grid;
        color: white;
    `,
    formgroup: css`
        display: flex;
        flex-direction: column;
        margin-bottom: 1em;

        label {
            margin-bottom: .5em;
            color: ${colors.soft};
        }
    `,
    textfield: css`
        background-color: ${colors.eventLight};
        border: none;
        border-radius: .25em;
        height: 2em;
        font-size: 1em;
        padding: 0.5em;
        color: ${colors.white};
    `,
    textarea: css`
        height: 10em;
        width: 100%;
        resize: none;
        font-family: Heebo, sans-serif, sans-serif;
        color: ${colors.white};
    `,
    afterSubmitWrapper: css`
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 1.3em;
        margin-top: 1em;
    `,
}

export default function ContactForm({ prefill, ...props }) {
    const { formatMessage } = useIntl()

    function getPrefillContent() {
        return {
            requestAnalytics: {
                subject: formatMessage({ id: 'accessingAnalytics' }),
                body: formatMessage({ id: 'requestAnalyticsPrefill' }),
            }
        }
    }

    const [subject, setSubject] = useState(getPrefillContent()[prefill]?.subject || '')
    const [body, setBody] = useState(getPrefillContent()[prefill]?.body || '')
    const [isSuccess, setIsSuccess] = useState(false)
    const [error, setError] = useState()

    const { post } = useLdjsonApi()

    const handleSubmit = useCallback(async () => {
        const { data, error } = await post(`/api/current/support/contact`, {
            body: {
                subject: subject,
                message: body
            },
        })

        if (error) {
            setError(error);
        }

        if (data) {
            setIsSuccess(true)
        }
    })

    const form = (
        <>
            <small css={css`color: ${colors.soft};`}>
                <FormattedMessage id='contactSubTitle' />
            </small>
            <div css={style.form}>
                <div css={style.formgroup}>
                    <label htmlFor="subject">
                        <FormattedMessage id='subject' />
                    </label>
                    <input
                        css={style.textfield}
                        name="subject"
                        onChange={(e) => setSubject(e.target.value)}
                        type="text"
                        value={subject}
                    />
                </div>
                <div css={style.formgroup}>
                    <label htmlFor="body">
                        <FormattedMessage id='description' />
                    </label>
                    <textarea
                        css={[style.textfield, style.textarea]}
                        onChange={(e) => setBody(e.target.value)}
                        name="body"
                        value={body}
                    />
                </div>
                <Cta onClick={() => handleSubmit()} css={css`width: min-content; place-self: center;`}>
                    <FormattedMessage id='submitData' />
                </Cta>
            </div>
        </>
    )

    const sucessContext = (
        <div css={style.afterSubmitWrapper}>
            <FormattedMessage id='contactUsReceived' />
        </div>
    )

    const errorContext = (
        <div css={style.afterSubmitWrapper}>
            <FormattedMessage id='contactUsError' />
        </div>
    )

    return (
        <div css={style.cont}>
            <div css={[style.wrapper, css`${(prefill && !isSuccess && `
                box-shadow: 0 0 5px 1px ${colors.soft};
            `)}`]}>
                {isSuccess ? sucessContext : null}
                {error ? errorContext : null}
                {!isSuccess ? form : null}
            </div>
        </div>
    )
}