import React, { useContext } from 'react'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import { DayPicker } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { colors, timings } from '../../style/vars'
import LocaleContext from '../../context/Locale'

const style = {
    picker: css`
        color: ${colors.white};
        display: flex;
        justify-content: center;
        border-radius: 0.125em;
        z-index: 10000;
        margin: 0; 
        width: 100%;
        font-size: 1.5em;

        .rdp-caption {
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: column;

            * {
                flex-grow: 1;
            }

            .rdp-nav {
                width: unset;
                display: flex;
                width: 100%;
            }

            button {
                border-radius: 0px;
                flex-grow: 1;
            }
        }

        .rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
            color: ${colors.dark};
            background: ${colors.main1};
        }

        .rdp-caption > * {
            flex-shrink: 1;
        }

        .rdp-cell {
            height: min-content;
        }

        .rdp-months, .rdp-month {
            width: 100%;
        }

        .rdp-table {
            width: 100%;
            max-width: unset;
            min-width: unset;

            button {
                width: 100%;
                max-width: unset;
            }
        }

        .rdp-day {
            color: ${colors.soft};
            background-color: #32393F;
            border: 0.1em solid #292E33;
            border-radius: 0;
            padding: 1em 0.4em;
            outline: none;
            transition: all ${timings.responsive} ease;
        }
    `,
}

function DatePicker({
    onDayClick,
    ...props
}) {
    const { datefnsLocale } = useContext(LocaleContext)

    return (
        <DayPicker
            locale={datefnsLocale}
            css={style.picker}
            onDayClick={(date) => { onDayClick(date) }}
            {...props}
        />
    )
}

DatePicker.propTypes = {
    onDayClick: PropTypes.func,
}

export default DatePicker
