import React, { useMemo, useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { css } from '@emotion/react'
import { groupBy, uniqBy } from 'lodash'
import { colors } from '../../style/vars'
import FallbackMessage from '../../components/_general/FallbackMessage'
import useRawApiState from '../../hooks/useRawApiState'
import PageSubtitle from '../../components/_general/PageSubtitle'
import PageTitle from '../../components/_general/PageTitle'
import ClubContext from '../../context/Club'
import LoaderText from '../../components/_general/LoaderText'
import EZGrid from '../../components/_layout/EZGrid'
import Loader from '../../components/_general/Loader'
import useLdjsonApi from '../../hooks/useLdjsonApi'
import PitchListContext from '../../context/PitchList'
import PitchCardWithData from '../../components/pitch/PitchCardWithData'
import { FormattedMessage } from 'react-intl'

function ClubPitchList({
    ...props
}) {
    const { get } = useLdjsonApi()
    const [club, { busy: clubBusy }] = useContext(ClubContext)
    const { data: logo, busy: logoBusy } = useRawApiState(club?.logo)
    const { defaultOrderPitches: pitchNameIdList } = useContext(PitchListContext)

    const [venues, setVenues] = useState()
    const [venuesBusy, setVenuesBusy] = useState()

    const pitches = useMemo(() => (uniqBy(club?.pitches || [], 'id')
    ), [club])

    const isBusy = useMemo(() => {
        return clubBusy || !club?.id || venuesBusy
    }, [clubBusy, club, venuesBusy])

    // pitches API does not include pitchSortRank values, but we have a sorted pitch list in context
    const pitchesSorted = useMemo(() => {
        // filter a copy of that list per venue
        if (!pitchNameIdList || !pitches) return []
        return pitchNameIdList.map(({ id }) => pitches.find(p => p.id === id)).filter(p => p)
    }, [pitchNameIdList, pitches])

    const venuesPitchesSorted = useMemo(() => {
        if (!venues) return []
        const venuePitches = pitchesSorted ? groupBy(pitchesSorted, 'venue') : {}
        // sort venuePitches by lowest pitchsortrank (lookup its index the pitch list array)
        return Object.entries(venuePitches)
            .sort(([aVenue, aPitches], [bVenue, bPitches]) => {
                const aIndices = aPitches.map(p => pitchNameIdList.indexOf(pitchNameIdList.find(listPitch => listPitch.id === p.id)))
                const bIndices = bPitches.map(p => pitchNameIdList.indexOf(pitchNameIdList.find(listPitch => listPitch.id === p.id)))
                return Math.min(...aIndices) - Math.min(...bIndices)
            })
            .map(([v, p]) => {
                const venueData = venues.find(ven => ven['@id'] === v)
                return {
                    venue: venueData,
                    venueName: venueData?.name, // may be undefined
                    pitches: p
                }
            })
    }, [pitchesSorted, venues])

    useEffect(() => {
        let isCancelled = false
        let buffer = []

        async function fetchVenues() {
            if (!pitches?.length) return
            if (!isCancelled) {
                setVenues([])
                setVenuesBusy(true)
            }
            try {
                const venue_list = [...(new Set(pitches.map(p => p.venue)))]
                for (const venue_id of venue_list) {
                    // fetch Data
                    const { data, error } = await get(venue_id)
                    // push to buffer
                    data && buffer.push(data)
                }
                if (!isCancelled) {
                    setVenues(buffer)
                }
            } catch (e) { console.log(e) } finally {
                if (!isCancelled) {
                    setVenuesBusy(false)
                }
            }
        }

        fetchVenues()

        return () => { isCancelled = true }
    }, [pitches])

    return (
        <div {...props}>
            <PageTitle css={css`display: flex; align-items: center; max-width: 100%; flex-wrap: wrap;`}>
                <NavLink css={css`text-decoration: none; color: inherit; white-space: nowrap;`} to='/'>
                    <FormattedMessage id='venues' /> &nbsp;
                </NavLink>
                <span css={css`color: ${colors.main1}; white-space: nowrap;`}>
                    {' / '}
                    {club?.name || <LoaderText subtle />}
                </span>
                {club?.logo && (
                    logo
                        ? <img
                            css={css`margin-left:.5em; height:2em; width:auto;`}
                            alt={club.name}
                            src={logo.file}
                        />
                        : <Loader size='2em' css={css`margin-left:.5em;`} />
                )}
            </PageTitle>
            {isBusy
                ?
                <>
                    <PageSubtitle>
                        <LoaderText />
                    </PageSubtitle>

                    <EZGrid w='23em' gap='2.5em'>
                        <PitchCardWithData />
                    </EZGrid>
                </>
                :
                Object.keys(venuesPitchesSorted).length === 0
                    ?
                    <FallbackMessage>
                        <FormattedMessage id='noPitchesWarn' />
                    </FallbackMessage>
                    :
                    venuesPitchesSorted.map(({ venueName, venue, pitches }, index) => (
                        <React.Fragment key={venueName ?? index}>
                            <PageSubtitle>
                                {venueName || <FormattedMessage id='unknownVenue' />}
                            </PageSubtitle>

                            <EZGrid w='23em' gap='2.5em' css={css`margin-bottom: 2.5em;`}>
                                {pitches?.map(pitch => (
                                    <PitchCardWithData key={pitch.id} venue={venue} pitch={pitch} />
                                ))}
                            </EZGrid>
                        </React.Fragment>
                    ))
            }
        </div>
    )
}

export default ClubPitchList
