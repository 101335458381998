import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react'
import FormLoader from './FormLoader'
import Pitch from '../../../context/Pitch'
import Orientation from '../../../components/test/Orientation'
import NumberScroller from '../../../components/_control/NumberScroller'
import { getUnixTime } from 'date-fns'
import FieldSectionSelector from '../../../components/_control/FieldSectionSelector'
import { css } from '@emotion/react'
import PropTypes from 'prop-types'
import useLdjsonApi from '../../../hooks/useLdjsonApi'
import EventDateTimePicker from '../../../components/_control/EventDateTimePicker'
import { FormattedMessage, useIntl } from 'react-intl'
import { zonesToSectionState } from '../../../utils/eventUtils'

const columns = 7
const rows = 5

export default function IrrigationForm({
    eventType,
    event,
    onPostRequest,
    ...props
}) {
    //utils
    const { post, put, get, del } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [millimeters, setMillimeters] = useState()
    const [meterValue, setMeterValue] = useState()
    const [sections, setSections] = useState([])
    //other
    const [pitch] = useContext(Pitch)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/irrigation-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setMillimeters(// we want to be strict and only prefill the field when ALL active zones have the same millis
                    data.zoneResults.filter(z => !!z.active).reduce((acc, curr) => {
                        if (acc === undefined) return curr.millimeters
                        if (acc === null) return null
                        return acc === curr.millimeters ? curr.millimeters : null
                    }, undefined) ?? ''
                )
                setMeterValue(data.meterValue)
                setSections(zonesToSectionState(data.zoneResults))
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    // when the required fields are set the form is valid and will submit.
    const validateAndSubmit = useCallback(async () => {
        const invalidNumMessage = formatMessage({ id: 'pleaseInputValidNum' })
        // millimeters and meterValue are optional
        if (!!millimeters && !Number.isFinite(millimeters)) return setFormError(invalidNumMessage)
        if (!!meterValue && !Number.isFinite(meterValue)) return setFormError(invalidNumMessage)
        // some zones are required
        if (!sections.flat().some(x => !!x)) return setFormError(formatMessage({ id: 'pleaseSelectZones' }))

        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setIsLoading(true)
        setFormError()

        const eventPayload = {
            meterValue: parseInt(meterValue),
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
        }

        if (event) {
            const { data: putResponse, error } = await put(`${event['@id']}`, { body: eventPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (putResponse) {
                const { data: irrigationData } = await get(`/api/current/irrigation-events/${putResponse.id}`)

                const putPromises = irrigationData.zoneResults.map(async (zone) => {
                    const body = {}
                    body.active = sections[zone.posY - 1][zone.posX - 1] ? 1 : null
                    body.millimeters = body.active ? (millimeters || null) : null
                    const { data, error } = await put(`${zone['@id']}`, { body })
                    return { data, error }
                })

                // the initial response data does not have avgmillimeters since it is calculated from zones we put later
                // let's be optimistic because aint nobody got time for all these put requests
                // we should refactor the usage of zones in these event forms later
                irrigationData.averageMillimeters = (millimeters || null)
                onPostRequest(true, irrigationData)  // closes the modal and adds partially fake event

                const results = await Promise.all(putPromises)
                if (results.some(r => r.error)) {
                    try {
                        // if something goes wrong delete the event
                        // at least it's better than saving a slightly off zone map
                        // To do: handle deletion of fake event when this happens
                        del(`/api/current/irrigation-events/${putResponse.id}`)
                    } catch (e) {
                        console.error(e)
                    }
                    return onPostRequest(false)
                }
            }
        } else {
            // new submission
            const newPayload = {
                ...eventPayload,
                name: `Irrigation event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data: postResponse, error } = await post(`/api/current/${eventType.path}`, { body: newPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (postResponse) {
                const { data: irrigationData } = await get(`/api/current/irrigation-events/${postResponse.id}`)

                const putPromises = irrigationData.zoneResults.map(async (zone) => {
                    const body = {}
                    body.active = sections[zone.posY - 1][zone.posX - 1] ? 1 : null
                    body.millimeters = body.active ? (millimeters || null) : null
                    const { data, error } = await put(`${zone['@id']}`, { body })
                    return { data, error }
                })

                // see previous comment
                irrigationData.averageMillimeters = (millimeters || null)
                onPostRequest(true, irrigationData)  // closes the modal and adds partially fake event

                const results = await Promise.all(putPromises)
                if (results.some(r => r.error)) {
                    try {
                        del(`/api/current/irrigation-events/${postResponse.id}`)
                    } catch (e) {
                        console.error(e)
                    }
                    return onPostRequest(false)
                }
            }
        }

    }, [pitch, millimeters, sections, event, meterValue, selectedDateTime, timeTaken])

    const isBusy = useMemo(() => {
        return isLoading
    }, [isLoading])

    return (
        <div {...props}>
            {isBusy ? <FormLoader /> : <>
                <EventDateTimePicker
                    unixDate={selectedDateTime}
                    setUnixDate={setSelectedDateTime}
                />
                <div css={[style.formGroup, css`width: 100%; margin-top: 1.5em;`]}>
                    <div css={css`display: flex; gap: 0.5em 2em; flex-wrap: wrap;`}>
                        <NumberScroller
                            label={formatMessage({ id: 'timeTaken' })}
                            minVal={0}
                            maxVal={24}
                            state={timeTaken}
                            unit={formatMessage({ id: 'hours' })}
                            step={0.5}
                            setState={setTimeTaken}
                        />
                        <NumberScroller
                            isOptional
                            label={formatMessage({ id: 'amountOptional' })}
                            minVal={0}
                            state={millimeters}
                            unit="mm"
                            setState={setMillimeters}
                            css={css`opacity: ${millimeters ? 1 : 0.5};`}
                        />
                        <NumberScroller
                            isOptional
                            label={formatMessage({ id: 'meterValueOptional' })}
                            minVal={0}
                            maxVal={999999999}
                            state={meterValue}
                            setState={setMeterValue}
                            css={css`opacity: ${meterValue ? 1 : 0.5};`}
                        />
                    </div>
                </div>
                <div css={style.formGroup}>
                    <h3>
                        <FormattedMessage id='areasCoveredIrrigation' />
                    </h3>
                    <FieldSectionSelector
                        backgroundType={pitch?.pitchBackground || pitch?.club?.sport}
                        rows={rows}
                        columns={columns}
                        sections={sections}
                        onChange={(sections) => setSections(sections)}
                    />
                    <Orientation
                        css={style.compass}
                        heading={pitch.orientation}
                    />
                </div>
            </>}
            {formError && <div>{formError}</div>}
            <button
                onClick={() => validateAndSubmit()}
                type='button'
                className="btn"
                disabled={isBusy}
            >
                <FormattedMessage id='save' />
            </button>
        </div>
    )
}

const style = {
    formGroup: css`
        margin-bottom: 1em;
        width: min-content;

        h3 {
            margin-bottom: .5em;
        }
    `,
    compass: css`
        position: relative;
        height: 2em;
        width: 2em;
        bottom: -1em;
        left: calc(50% - 1em);
        background-color: rgba(44,52,58);
        box-shadow: none;
    `,
}


IrrigationForm.propTypes = {
    pitch: PropTypes.object,
    eventType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    day: PropTypes.object,
    onPostRequest: PropTypes.func,
}