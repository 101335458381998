import React, { useCallback, useContext, useMemo, useState } from "react"
import Modal from "../../components/_layout/Modal"
import Collapse from "../../components/_general/Collapse"
import { format } from "date-fns"
import { css } from '@emotion/react'
import pencilIcon from "../../assets/icons/pencil.svg"
import trashIcon from "../../assets/icons/trash.svg"
import EventTypeIcon from "./EventTypeIcon"
import { ReactSVG } from "react-svg"
import Proptypes from "prop-types"
import EventSummary  from "../../components/events/EventSummary"
import { useEffect } from "react"
import { MTM_EVENT_TYPES, MTM_VARIABLES } from "../../utils/matomo"
import useTracker from "../../hooks/useTracker"
import LocaleContext from "../../context/Locale"
import DeleteWarnModal from "../_control/DeleteWarnModal"
import { useIntl } from "react-intl"
import useBridge from "../../hooks/useBridge"

export default function DaySummaryModal({ daySummaryData, setDaySummaryData, deleteEvent, setSelectedEvent, ...props }) {

    const {data: eventTypes, loading: loadingEventTypes} = useBridge('/api/current/frontend-bridge/event-types')

    const { formatMessage } = useIntl()

    const track = useTracker()
    const { datefnsLocale } = useContext(LocaleContext)

    const [eventToDelete, setEventToDelete] = useState()
    const [deleteModal, setDeleteModal] = useState(false)
    const [deletionError, setDeletionError] = useState('')

    const handleDelete = useCallback(async () => {
        if (!eventToDelete) return
        try {
            const { error } = await deleteEvent(eventToDelete)
            if (error) {
                setDeletionError(formatMessage({ id: 'somethingWentWrongTryAgain' }))
                throw new Error('Something went wrong')
            } else {
                track({
                    'event': MTM_EVENT_TYPES["delete-event"],
                    [MTM_VARIABLES["event-type"]]: eventToDelete.type
                })
                setDeleteModal(false)
            }
        } catch (e) {
            console.error(e)
        }
    }, [eventToDelete, deleteEvent])

    const deleteMessage = useMemo(() => {
        //'to be translated: Are you sure you want to delete this event?'
        if (!eventToDelete) return
        return deletionError || formatMessage(
            { id: 'deleteEventWarnMessage' },
            {
                activityType: eventTypes?.find(x => x.entityShortName === eventToDelete.type).entityShortNameLocalised,
                date: format(new Date(eventToDelete?.timestamp * 1000), 'PP', { locale: datefnsLocale })
            }
        )
    }, [deletionError, eventToDelete, eventTypes])

    const groupEvents = useCallback((events) => {
        const types = []
        const groupedEventLists = []

        if (events) {
            for (const event of events) {
                if (!types.includes(event.type)) types.push(event.type)
            }
        }

        for (const type of types) {
            groupedEventLists.push(events.filter(item => item.type === type).map(event => {
                return {
                    header: (
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}>
                            <EventTypeIcon
                                style={{
                                    marginRight: "1em"
                                }}
                                counter={events.filter(item => item.type === type).length}
                                eventType={event.type}
                            />
                            {eventTypes?.find(x => x.entityShortName === event.type).entityShortNameLocalised}
                        </div>
                    ),
                    content: (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                marginRight: "16px"
                            }}>
                            {<EventSummary event={event}/>}
                        </div >
                    ),
                    actions: (
                        <div
                            css={css`
                                display: flex;
                                justify-content: space-around;
                                gap: 10px;
                                @media screen and (max-width: 600px) {
                                    flex-direction: column;
                                }
                            `}>
                            <div
                                css={styles.collapseActionButton}
                                onClick={() => setSelectedEvent(event)}>
                                <ReactSVG src={pencilIcon} />
                            </div>
                            <div
                                css={styles.collapseActionButton}
                                onClick={() => {
                                    setEventToDelete(event)
                                    setDeleteModal(true)
                                }}>
                                <ReactSVG src={trashIcon} />
                            </div>
                        </div>
                    ),
                    date: format(
                        new Date(event.timestamp * 1000),
                        "PP",
                        { locale: datefnsLocale }
                    )
                };
            }))
        }
        return groupedEventLists;
    }, [daySummaryData, eventTypes]);

    useEffect(() => {
        track({
            'event': MTM_EVENT_TYPES["view-calendar-day"],
        })
    }, [])

    return (
        <>
            <Modal
                header={format(
                    new Date(daySummaryData[0].timestamp * 1000),
                    "PP",
                    { locale: datefnsLocale }
                )}
                onClickClose={() => { setDaySummaryData(null) }}
                css={css`width: min(95%, 45em)`}>
                {groupEvents(daySummaryData).map((item, key) => (
                    <Collapse key={key} data={item} defaultOpen={true} />
                ))}
            </Modal>

            {deleteModal &&
                <DeleteWarnModal
                    header={formatMessage({ id: 'deleteEvent' })}
                    message={deleteMessage}
                    handleClose={() => {
                        setDeleteModal(false)
                        setEventToDelete()
                        setDeletionError()
                    }}
                    handleDelete={handleDelete}
                />
            }
        </>
    )
}

DaySummaryModal.propTypes = {
    daySummaryData: Proptypes.array.isRequired,
    setDaySummaryData: Proptypes.func.isRequired,
    deleteEvent: Proptypes.func.isRequired,
    setSelectedEvent: Proptypes.func.isRequired,
}

const styles = {
    collapseActionButton: css`
        width: 18px;
        height: 18px;
        margin-right: 20px;
        cursor: pointer;

        &:hover {
            svg,
            path {
                fill: #07d0a5;
            }
        }

        svg {
            fill: #6d7884;
        }
    `
}
