
import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react'
import Selector from '../../../components/_control/OptionsSelector'
import useRawApiState from '../../../hooks/useRawApiState'
import FancyDropdown from '../../../components/_control/FancyDropdown'
import useLdjsonApi from '../../../hooks/useLdjsonApi'
import { getUnixTime } from 'date-fns'
import { css } from '@emotion/react'
import FormLoader from './FormLoader'
import EventDateTimePicker from '../../../components/_control/EventDateTimePicker'
import useBridge from '../../../hooks/useBridge'
import { FormattedMessage, useIntl } from 'react-intl'
import NumberScroller from '../../../components/_control/NumberScroller'
import PitchContext from '../../../context/Pitch'

export default function BioTreatmentform({
    eventType,
    event,
    onPostRequest,
}) {
    //utils
    const { post, put, get } = useLdjsonApi()
    const { formatMessage } = useIntl()
    //field state
    const [selectedDateTime, setSelectedDateTime] = useState()
    const [timeTaken, setTimeTaken] = useState(0)
    const [treatmentMethod, setTreatmentMethod] = useState()
    const [treatmentType, setTreatmentType] = useState()
    const [fungicideProduct, setFungicideProduct] = useState()
    const [nematodeProduct, setNematodeProduct] = useState()
    //other
    const { data: treatmentTypeOptions, loading: WaitingFortreatmentTypeOptions } = useBridge(`/api/current/frontend-bridge/biotreatment-event-treatment-types`)
    const { data: treatmentMethodOptions, loading: WaitingForTreatmentMethodOptions } = useBridge(`/api/current/frontend-bridge/biotreatment-event-treatment-methods`)
    const { data: fungicideOptions, loading: WaitingForFungicideOptions } = useBridge(`/api/current/fungicide-products`)
    const { data: nematodeOptions, loading: WaitingForNematodeOptions } = useBridge(`/api/current/nematode-products`)
    const [pitch] = useContext(PitchContext)

    const [formError, setFormError] = useState()
    const [isLoading, setIsLoading] = useState(!!event)

    useEffect(() => {
        if (event) {
            async function getEventAndHydrateFormData(eventId) {
                setIsLoading(true)
                const { data } = await get(`/api/current/bio-treatment-events/${eventId}`)
                setSelectedDateTime(new Date(data.timestamp * 1000))
                setTimeTaken(data.timeTaken / 60)
                setTreatmentMethod(data.treatmentMethod)
                setTreatmentType(data.treatmentType)
                setFungicideProduct(data.fungicideProduct?.name)
                setNematodeProduct(data.nematodeProduct?.name)
                setIsLoading(false)
            }
            getEventAndHydrateFormData(event.id)
        }
    }, [event])

    const validateAndSubmit = useCallback(async () => {
        let eventProps = {
            timestamp: getUnixTime(selectedDateTime),
            timeTaken: parseInt(timeTaken * 60),
            treatmentType,
        }

        switch (treatmentType) {
            case 'disease-treatment':
                if (!treatmentMethod) return setFormError(formatMessage({ id: 'pleaseSelectTreatmentMethod' }))
                if (treatmentMethod === 'chemical' && !fungicideProduct) return setFormError(formatMessage({ id: 'pleaseSelectFungProd' }))
                eventProps = {
                    ...eventProps,
                    treatmentMethod,
                    fungicideProduct: treatmentMethod === 'chemical'
                        ? fungicideProduct?.value
                        : null,
                    nematodeProduct: null,
                }
                break

            case 'nematode-treatment':
                if (!nematodeProduct) return setFormError(formatMessage({ id: 'pleaseSelectPSProduct' }))
                eventProps = {
                    ...eventProps,
                    treatmentMethod: null,
                    fungicideProduct: null,
                    nematodeProduct: nematodeProduct?.value,
                }
                break

            default: return setFormError(formatMessage({ id: 'pleaseSelectTreatmentType' }))
        }

        if (!Number.isFinite(timeTaken)) return setFormError(formatMessage({ id: 'pleaseEnterTimeTaken' }))

        setFormError()
        setIsLoading(true)

        if (event) {

            const { data, error } = await put(`${event['@id']}`, { body: eventProps })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        } else {
            const eventPayload = {
                ...eventProps,
                name: `Biotreatment event for pitch ${pitch['id']}`,
                pitch: pitch['@id'],
            }

            const { data, error } = await post(`/api/current/${eventType.path}`, { body: eventPayload })

            if (error) {
                setIsLoading(false)
                onPostRequest(false, error)
            }
            else if (data) {
                onPostRequest(true, data)
            }
        }
    }, [event, treatmentType, treatmentMethod, fungicideProduct, nematodeProduct, onPostRequest, selectedDateTime, timeTaken])

    const isBusy = useMemo(
        () => (
            WaitingFortreatmentTypeOptions ||
            WaitingForTreatmentMethodOptions ||
            WaitingForFungicideOptions ||
            WaitingForNematodeOptions ||
            isLoading
        ), [
        WaitingFortreatmentTypeOptions,
        WaitingForTreatmentMethodOptions,
        WaitingForFungicideOptions,
        WaitingForNematodeOptions,
        isLoading,
    ])

    return <div css={{ overflow: "visible" }}>
        {isBusy ? <FormLoader /> : <>
            <EventDateTimePicker
                unixDate={selectedDateTime}
                setUnixDate={setSelectedDateTime}
            />
            <NumberScroller
                label={formatMessage({ id: 'timeTaken' })}
                minVal={0}
                maxVal={24}
                state={timeTaken}
                unit={formatMessage({ id: 'hours' })}
                step={0.5}
                setState={setTimeTaken}
                css={css`width: min-content;`}
            />
            <Selector
                label={formatMessage({ id: 'type' })}
                optionsObject={treatmentTypeOptions}
                setState={(value) => {
                    setTreatmentType(value)
                }}
                state={treatmentType}
                btnHeight={50}
            />
            {treatmentType === 'disease-treatment' && <Selector
                label={formatMessage({ id: 'methods' })}
                optionsObject={treatmentMethodOptions}
                setState={(value) => setTreatmentMethod(value)}
                state={treatmentMethod}
                btnHeight={50} />
            }
            {treatmentType === 'disease-treatment' && treatmentMethod === 'chemical' ?
                <>
                    <h3 css={{ marginBottom: 8 }}>
                        {formatMessage({ id: 'product' })}
                    </h3>
                    <FancyDropdown
                        options={fungicideOptions?.map(
                            ({ '@id': hydraId, name }) => (
                                {
                                    label: name,
                                    value: hydraId
                                }
                            )) || []}
                        placeholder={formatMessage({ id: 'chooseFungicideProduct' })}
                        onChange={(e) => setFungicideProduct(e)}
                        value={fungicideProduct}
                        css={dropdownStyle}
                    />
                </>
                : treatmentType === 'nematode-treatment' &&
                <>
                    <h3 css={{ marginBottom: 8 }}>
                        {formatMessage({ id: 'product' })}
                    </h3>
                    <FancyDropdown
                        options={nematodeOptions?.map(
                            ({ '@id': hydraId, name }) => (
                                {
                                    label: name,
                                    value: hydraId
                                }
                            )) || []}
                        placeholder={formatMessage({ id: 'choosePlantSoilProduct' })}
                        onChange={(e) => setNematodeProduct(e)}
                        value={nematodeProduct}
                        css={dropdownStyle}
                    />
                </>
            }
        </>}
        {formError && <div>{formError}</div>}
        <button
            className="btn"
            type="button"
            disabled={isBusy}
            onClick={() => validateAndSubmit()}
        >
            <FormattedMessage id='save' />
        </button>
    </div>
}

const dropdownStyle = css`
    width: 100%;
    border-radius: 5px;
    border: 2px solid #394047;
    background-color: #2F353B;
    margin-bottom: 1em;
    margin: 16px 0;

    select {
        border-bottom: 0;
        padding-left: 8px;
    }

    >div {
        padding: 1em;
    }
`