import React, { useMemo } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import { css } from '@emotion/react'
import { colors } from '../../style/vars'
import { FormattedMessage, useIntl } from 'react-intl'

function DeviceObservations({ device, obs_type, observations = [], ...props }) {

    const { formatMessage } = useIntl()
    const noDataPlaceholder = formatMessage({ id: 'noDataAvailable' })
    const placeholderFormatted = noDataPlaceholder[0] + noDataPlaceholder.toLowerCase().slice(1)

    // "Decode" the observations data
    const values = useMemo(() => {
        switch (obs_type) {
            case "obs_st_ext":
                const [
                    timestamp,
                    pressure, pressure_high, pressure_low,
                    temp, temp_high, temp_low,
                    humidity, humidity_high, humidity_low,
                    lux, lux_high, lux_low,
                    uv, uv_high, uv_low,
                    sol_rad, sol_rad_high, sol_rad_low,
                    wind_avg, wind_gust, wind_lull, wind_dir, wind_interval,
                    thunder_strike_count, thunder_strike_avg_distance, record_count,
                    battery,
                    precip_acc_today_local, precip_acc_today_local_final,
                    precip_mins_today_local, precip_mins_today_local_final,
                    precip_type, precip_analysis_type
                ] = observations;

                return {
                    timestamp,
                    pressure, pressure_high, pressure_low,
                    temp, temp_high, temp_low,
                    humidity, humidity_high, humidity_low,
                    lux, lux_high, lux_low,
                    uv, uv_high, uv_low,
                    sol_rad, sol_rad_high, sol_rad_low,
                    wind_avg, wind_gust, wind_lull, wind_dir, wind_interval,
                    thunder_strike_count, thunder_strike_avg_distance, record_count,
                    battery,
                    precip_acc_today_local, precip_acc_today_local_final,
                    precip_mins_today_local, precip_mins_today_local_final,
                    precip_type, precip_analysis_type
                }
            default:
                return {}
        }

    }, [obs_type, observations])

    return (
        <div css={css`display: flex; flex-direction: column; align-items: stretch; grow: 1;`}
            {...props}
        >
            <span css={{
                fontSize: "2em",
                alignSelf: "center",
                opacity: 0.5,
            }}>
                {device?.device_meta?.name ?? <FormattedMessage id='unnamedDevice' />}
            </span>

            {observations?.length ?
                <div css={style.grid}>
                    <div css={style.label}> <FormattedMessage id='temperature' css={css`opacity: 0.8;`}/> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='averageAbbr' /></span> <span>{values?.temp + ' °C'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='minAbbr' /></span> <span>{values?.temp_low + ' °C'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='maxAbbr' /></span> <span>{values?.temp_high + ' °C'}</span>
                    </div>
                    <div />

                    <div css={style.label}> <FormattedMessage id='humidity' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='averageAbbr' /></span> <span>{values?.humidity + ' %'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='minAbbr' /></span> <span>{values?.humidity_low + ' %'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='maxAbbr' /></span> <span>{values?.humidity_high + ' %'}</span>
                    </div>
                    <div />

                    <div css={style.label}> <FormattedMessage id='pressure' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='averageAbbr' /></span> <span>{values?.humidity + ' mbar'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='minAbbr' /></span> <span>{values?.humidity_low + ' mbar'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='maxAbbr' /></span> <span>{values?.humidity_high + ' mbar'}</span>
                    </div>
                    <div />

                    <div css={style.label}> <FormattedMessage id='lightning' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='lightningStrikesShort' /></span> <span>{values?.thunder_strike_count}</span>
                    </div>
                    {values?.thunder_strike_avg_distance ?
                        <div css={style.field}>
                            <span><FormattedMessage id='averageAbbr' />. <FormattedMessage id='distance' /></span> <span>{values?.thunder_strike_avg_distance + ' km'}</span>
                        </div>
                        : <div />
                    }
                    <div />
                    <div />

                    <div css={style.label}> <FormattedMessage id='wind' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='averageAbbr' /></span> <span>{values?.wind_avg + ' m/s'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='direction' /></span> <span>{values?.wind_dir + ' °'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='windGust' /></span> <span>{values?.wind_gust + ' m/s'}</span>
                    </div>
                    <div css={[style.field, css`overflow: hidden;`]}>
                        <span><FormattedMessage id='lull' /></span> <span>{values?.wind_lull + ' m/s'}</span>
                    </div>

                    <div css={style.label}> <FormattedMessage id='precipitation' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='duration' /></span> <span>{values?.precip_mins_today_local + ' min'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='total' /></span> <span>{values?.precip_acc_today_local + ' mm'}</span>
                    </div>
                    {values?.precip_type === 2 ?
                        <div css={style.field}>
                            <span><FormattedMessage id='type' /></span>
                            <span><FormattedMessage id={'hail'} /></span>
                        </div>
                        :
                        <div />
                    }
                    <div />

                    <div css={style.label}> <FormattedMessage id='brightness' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='averageAbbr' /></span> <span>{values?.lux + ' lux'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='minAbbr' /></span> <span>{values?.lux_low + ' lux'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='maxAbbr' /></span> <span>{values?.lux_high + ' lux'}</span>
                    </div>
                    <div />

                    <div css={style.label}> <FormattedMessage id='uvIndex' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='averageAbbr' /></span> <span>{values?.uv}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='minAbbr' /></span> <span>{values?.uv_low}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='maxAbbr' /></span> <span>{values?.uv_high}</span>
                    </div>
                    <div />

                    <div css={style.label}> <FormattedMessage id='solarRadiation' /> </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='averageAbbr' /></span> <span>{values?.sol_rad + ' W/m²'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='minAbbr' /></span> <span>{values?.sol_rad_low + ' W/m²'}</span>
                    </div>
                    <div css={style.field}>
                        <span><FormattedMessage id='maxAbbr' /></span> <span>{values?.sol_rad_high + ' W/m²'}</span>
                    </div>
                    <div />

                </div>

                : <div css={{
                    alignSelf: "center",
                    marginTop: "4em",
                    fontSize: "1.5em"
                }}>
                    {placeholderFormatted}
                </div>
            }
        </div>
    )
}

DeviceObservations.propTypes = {
    device_id: PropTypes.object,
    obs_type: PropTypes.oneOf([
        "obs_air",
        "obs_sky",
        "obs_st",
        "obs_st_ext"
    ]).isRequired,
    observations: PropTypes.arrayOf(oneOfType([
        PropTypes.string,
        PropTypes.number,
    ])),
}

export default DeviceObservations

const style = {
    grid: css`
        display: grid;
        width: min-content;
        place-self: center;
        grid-template-columns: 1fr repeat(4, 10em);
        grid-auto-rows: 3em;
        margin-top: 1em;
        gap: 0.6em;
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr repeat(3, 6em) 0fr;
            gap: 0.2em;
        }
    `,
    label: css`
        font-size: 1.5em;
        text-align: right;
        margin-right: 3em;
        align-self: center;
        @media screen and (max-width: 600px) {
            margin-right: 0.2em;
        }
    `,
    field: css`
        place-self: center;
        display: grid;
        place-items: center;
        gap: 0.2em;
        width: 100%;
        height: 100%;
        background: ${colors.eventDark};
        border-radius: 5px;
        text-transform: lowercase;

        &:[first-child] {
            color: ${colors.soft};
        }
    `
}